import { popper } from "@popperjs/core";
import bootstrap from "bootstrap";
import Swiper from "swiper/bundle";
import fslightbox from "fslightbox"

const swiper = new Swiper(".swiper-container", {
  slidesPerView: 2,
  spaceBetween: 15,
  breakpoints: {
    576: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    992: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 7,
      spaceBetween: 20,
    },
    1400: {
      slidesPerView: 8,
      spaceBetween: 20,
    },
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },

  // Optional parameters
  loop: true,

  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
  },

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  // And if we need scrollbar
});

const swiperCourses = new Swiper(".Swiper-courses", {
  effect: "coverflow",
  grabCursor: true,
  centeredSlides: false,
  slidesPerView: "auto",
  loop: true,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
  navigation: {
    nextEl: ".courses-button-next",
    prevEl: ".courses-button-prev",
  },
});
